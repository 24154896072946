import React from 'react';
import Image from 'next/image';
import styles from './kia-tsamaya.module.scss';
import cn from 'classnames';
import { CustomHead } from '../head/head';

import bg from '../../public/tsamaya_bg.jpg';
import tsamayaLogo from '../../public/tsa_logo.png';
import facebook from '../../public/facebook-fa.png';
import twitter from '../../public/twitter-fa.png';
import whatsapp from '../../public/whatsapp-fa.png';
import email from '../../public/envelope-solid-fa.png';

function KiaTsamaya({ seo }) {
  return (
    <>
      <CustomHead seo={seo} />
      <section
        className={styles.tsamayaBanner}
        style={{ backgroundImage: `url(${bg.src})` }}
      >
        <Image
          src={tsamayaLogo}
          className={styles.tsamaya_logo}
          alt="Tsamaya Logo"
        />
        <div className={styles.tsa_offset_video}>
          <div className={styles.inner_box}>
            <div className={styles.video_embed_container}>
              <iframe
                src="https://ljsp.lwcdn.com/api/video/embed.jsp?id=d3d9564a-b195-4de5-b74a-46acc10f8ed2&pi=9ba18b71-9732-4e24-b215-f2e6ba6b0a17"
                frameBorder="0"
                allow="autoplay"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </section>
      <section
        className={cn(
          styles.tsa_pg_section_block,
          styles.inner_box,
          styles.theStory
        )}
      >
        <h1>The Story</h1>
        <p>
          And then, there was movement. Movement that creates moments, movement
          that fosters memories, Movement that Inspires.
        </p>
        <p>
          South Africans are a nation of movers. We move to chase our biggest
          dreams, move to the rhythmic beat of our own unique drum, move off the
          beaten path, move to spaces and faces familiar and new.
        </p>
        <p>
          And in every movement, we seek and find inspiration. The young child
          marveling at the picturesque mountain peaks of the open road. The
          career focused someone who finds moments of peace in movement. The
          teen who treats the backseat like their personal Tik Tok content
          studio. The couple whose bond grows stronger with every joyous
          journey. The family that creates cherished memories while road
          tripping.
        </p>
        <p>
          The Kia Road Trip Anthem is a celebration. A celebration of movement,
          inspiration, journeys, road trips, and more. It is a celebration of
          Mzansi and its people.
        </p>
        <p>Here’s to every road trip, here’s to every Kia Tsamaya moment.</p>
      </section>
      <section className={cn(styles.tsa_pg_section_block, styles.bgGray)}>
        <div className={styles.inner_box}>
          <div className={styles.anthemTitle}>
            <h2 className={styles.textCenter}>Kia Tsamaya Anthem</h2>
            <p className={styles.textCenter}>
              Fall in love with the sound of every road trip, every moment spent
              moving, being inspired, and arriving. Sing it loud and sing it
              proud – the Kia Tsamaya Anthem.
            </p>
          </div>
          <div className={styles.audioPlayer_block}>
            <div className={styles.audioContent}>
              <iframe
                src="https://open.spotify.com/embed/track/6bLWE46r9TWeamE5Kr3gFB?utm_source=generator&amp;theme=0"
                width="100%"
                height="152"
                frameBorder="0"
                allowFullScreen
              />
              <a
                href="https://open.spotify.com/album/31zLASQKyZyfHEgeWQ3lX2?si=_8yTHeEpQ-Klv2__gk0zdg&amp;nd=1"
                target="_blank"
                className={styles.spotify_share}
              >
                Add this song to my playlist
              </a>
            </div>
            <div className={styles.audioContent}>
              <iframe
                src="https://embed.music.apple.com/za/album/kia-tsamaya-feat-katlego-nkoana/1654089064?i=1654089065"
                width="100%"
                height="152"
                frameBorder="0"
                allowFullScreen
              />
              <a
                href="https://music.apple.com/za/album/kia-tsamaya-feat-katlego-nkoana-single/1654089064"
                className={styles.apple_share}
                target="_blank"
              >
                Add this song to my playlist
              </a>
            </div>
          </div>
          <a
            href="https://tidal.com/browse/track/259862850"
            className={styles.tidal_share}
            target="_blank"
          >
            Listen to this song on TIDAL
          </a>
          <h2 className={styles.textCenter}>Share</h2>
          <div className={styles.share_icons}>
            <a
              href="http://www.facebook.com/sharer/sharer.php?u=https://www.kia.co.za/kiatsamaya&title=Kia%20Tsamaya"
              className={cn(styles.share_btn, styles.share_btn_facebook)}
            >
              <span className={styles.share_btn_icon}>
                <Image src={facebook} width={18} height={18} alt="" />
              </span>
              <div className={styles.share_btn_text}>
                <span className={styles.share_btn_title}>Facebook</span>
              </div>
            </a>
            <a
              href="http://twitter.com/intent/tweet?original_referer=https://www.kia.co.za/kiatsamaya&text=Kia%20Tsamaya&url=https://www.kia.co.za/kiatsamaya"
              className={cn(styles.share_btn, styles.share_btn_twitter)}
            >
              <span className={styles.share_btn_icon}>
                <Image src={twitter} width={18} height={18} alt="" />
              </span>
              <div className={styles.share_btn_text}>
                <span className={styles.share_btn_title}>Twitter</span>
              </div>
            </a>
            <a
              href="https://api.whatsapp.com/send?text=*Kia%20Tsamaya*%0A%0Ahttps://www.kia.co.za/kiatsamaya"
              className={cn(styles.share_btn, styles.share_btn_whatsapp)}
            >
              <span className={styles.share_btn_icon}>
                <Image src={whatsapp} width={18} height={18} alt="" />
              </span>
              <div className={styles.share_btn_text}>
                <span className={styles.share_btn_title}>WhatsApp</span>
              </div>
            </a>
            <a
              href="mailto:?subject=Kia%20Tsamaya&body=Listen to it here https://www.kia.co.za/kiatsamaya"
              className={cn(styles.share_btn, styles.share_btn_email)}
            >
              <span className={styles.share_btn_icon}>
                <Image src={email} width={18} height={18} alt="" />
              </span>
              <div className={styles.share_btn_text}>
                <span className={styles.share_btn_title}>Email</span>
              </div>
            </a>
          </div>
          <div className={styles.tsamaya_lyrics}>
            <h2 className={styles.textCenter}>Kia Tsamaya Lyrics</h2>
            <a
              href="https://kia.co.za/assets/files/Kia%20Tsamaya%20Lyrics.pdf?_gl=1*yw5fjm*_ga*OTgzNDAwNTA2LjE3MDY1MTU0NzU.*_ga_32X728D358*MTcxMDM0MjI2MS4yNi4xLjE3MTAzNDQ0MjIuNjAuMC4xMzc4NzU0NDMz"
              className={styles.download_lyrics}
              target="_blank"
            >
              Download the lyrics
            </a>
          </div>
        </div>
      </section>
      <section className={styles.tsa_pg_section_block}>
        <div className={styles.inner_box}>
          <div className={styles.tsa_videoListing}>
            <div className={styles.tsa_vid_box}>
              <div className={styles.video_embed_container}>
                <iframe
                  src="https://ljsp.lwcdn.com/api/video/embed.jsp?id=d462b278-d5b2-471c-920c-35b9fded724c&amp;pi=9ba18b71-9732-4e24-b215-f2e6ba6b0a17"
                  frameBorder="0"
                  title="0"
                  allow="autoplay"
                  allowFullScreen
                />
              </div>
              <div className={styles.tsa_vid_boxCopy}>
                <h3 className={styles.mb20}>The Family Journeys</h3>
                <p>
                  Celebrate and enjoy the unforgettable family journeys. The
                  journeys with loved ones, the ones who are always there for
                  you, the ones who matter most.
                </p>
              </div>
            </div>
            <div className={styles.tsa_vid_box}>
              <div className={styles.video_embed_container}>
                <iframe
                  src="https://ljsp.lwcdn.com/api/video/embed.jsp?id=0e302706-94e6-479f-9474-20dd627e9ea9&amp;pi=9ba18b71-9732-4e24-b215-f2e6ba6b0a17"
                  frameBorder="0"
                  title="0"
                  allow="autoplay"
                  allowFullScreen
                />
              </div>
              <div className={styles.tsa_vid_boxCopy}>
                <h3 className={styles.mb20}>The Couple Road Trips</h3>
                <p>
                  Here’s to road trips with your soulmate. To enjoying the
                  moments of movement that feature stolen kisses, passionate
                  proclamations of love, and silent moments you’ll cherish
                  forever.
                </p>
              </div>
            </div>
            <div className={styles.tsa_vid_box}>
              <div className={styles.video_embed_container}>
                <iframe
                  src="https://ljsp.lwcdn.com/api/video/embed.jsp?id=b774d443-9d46-4e8e-b986-bac691ca2a96&amp;pi=9ba18b71-9732-4e24-b215-f2e6ba6b0a17"
                  frameBorder="0"
                  title="0"
                  allow="autoplay"
                  allowFullScreen
                />
              </div>
              <div className={styles.tsa_vid_boxCopy}>
                <h3 className={styles.mb20}>Solo Adventurers</h3>
                <p>
                  The solo adventures are the journeys that allow you to enjoy
                  you. Marvel at the road you’ve travelled, the dreams you’ve
                  made a reality, and the joy of knowing that you’re well on
                  your way to achieving all you’ve ever wanted.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default KiaTsamaya;
