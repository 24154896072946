'use client';

import StyleWrapper from '@/app/_components/StyleWrapper';
import KiaTsamaya from '@/old-components/kia-tsamaya/kia-tsamaya';
import { getImageLink } from '@/services/link.service';

const KiaTsamayaPage = () => {
  const seo = {
    title: `Kia South Africa | Movement that inspires`,
    description: `Explore KIA South Africa's range of new cars, SUVs, MPVs and Commercial Vehicles. Book a Test drive at your closest KIA dealer and read the latest news about KIA South Africa.`,
    image: getImageLink('c3038b36-5df7-45d3-acfe-ad136266cf95'),
    keywords: '',
  };

  return (
    <StyleWrapper>
      <KiaTsamaya seo={seo} />
    </StyleWrapper>
  );
};

export default KiaTsamayaPage;
