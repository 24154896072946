import { frontendBaseUrl } from '@/constants/links/links.constants';
import { DEFAULT_TITLE } from '@/constants/vars.constants';
import { getImageLink } from '@/services/link.service';
import { SupportedStructuredData } from '@/types/head/head.types';
import { SEO } from '@/types/home/home.types';
import { usePathname } from 'next/navigation';
import Head from 'next/head';
import React from 'react';
export const CustomHead: React.FC<{
  seo: SEO;
  structuredData?: SupportedStructuredData[];
  children?: React.ReactNode;
}> = ({ seo, children, structuredData }) => {
  const asPath = usePathname();
  const canonicalUrl =
    typeof window !== 'undefined'
      ? window.location.origin + asPath
      : frontendBaseUrl + asPath;

  return (
    <Head>
      <meta
        name="description"
        content={
          seo?.description ? seo?.description.replace(/(<([^>]+)>)/gi, '') : ''
        }
      />
      <meta
        prefix="og: https://ogp.me/ns#"
        property="og:title"
        content={seo?.title || DEFAULT_TITLE}
      />
      <meta
        prefix="og: https://ogp.me/ns#"
        property="og:description"
        content={seo?.description || ''}
      />
      <meta
        prefix="og: https://ogp.me/ns#"
        property="og:image"
        content={seo?.image || ''}
      />
      <meta name="msvalidate.01" content="" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@KiaMotorsSA" />
      <meta name="twitter:title" content={seo?.title || DEFAULT_TITLE} />
      <meta name="twitter:description" content={seo?.description || ''} />
      <meta name="twitter:image" content={seo?.image || ''} />
      <meta name="keywords" content={seo?.keywords || ''} />
      <meta name="messages" content={seo?.description || ''} />
      <meta charSet="UTF-8" />
      <link rel="canonical" href={canonicalUrl} />
      <link
        rel="shortcut icon"
        sizes="48x48"
        href={getImageLink('3933613d-5167-4d0b-8f6a-b4ca90723019')}
      />
      <title>{seo?.title || DEFAULT_TITLE} </title>
      {structuredData && (
        <>
          {structuredData.map((sd) => {
            const sdWithContext: SupportedStructuredData & {
              '@context': 'https://schema.org';
            } = {
              '@context': 'https://schema.org',
              ...sd,
            };

            return (
              <script
                key={`${sd['@type']}-structured-data`}
                type="application/ld+json"
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(sdWithContext),
                }}
              />
            );
          })}
        </>
      )}

      {children}
    </Head>
  );
};
